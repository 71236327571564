// useAuthentication.js
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { URL } from '../service/Service';

function useAuthentication() {
  const location = useLocation();
  const token = sessionStorage.getItem('jwt');
  const navigate = useNavigate();

  useEffect(() => {
    // Redireciona para '/' se não houver token e não estiver nas páginas de resetpassword
    if (!token && !location.pathname.includes('/resetpassword')) {
      navigate('/');
    } else if (token) {
      // Valida o token e redireciona com base no papel do usuário
      axios.get(`${URL}/api/validate`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        const role = jwtDecode(token).authorities[0].authority;

        if ((role === 'ADMIN' || role === 'MANAGER') && !location.pathname.startsWith('/admin/')) {
          navigate('/admin/dashboard'); // Adjust this path to your main admin route
        } else if (role === 'PARTNER' && !location.pathname.startsWith('/user/')) {
          navigate('/');
        }
      })
      .catch(error => {
        console.log(error);
        navigate('/');
      });
    }
  }, [token, location.pathname, navigate]);
}

export default useAuthentication;
