import { useEffect, useState } from "react";
import { URL } from "../service/Service";

function useFetchIDPartner(jwtToken) {
  const [loading, setLoading] = useState(true);
  const [partnerID, setPartnerID] = useState('');

  useEffect(() => {
    if (jwtToken) {
      fetch(`${URL}/api/afil`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Network response was not ok');
      })
      .then((fetchedData) => {
        setPartnerID(fetchedData.partnerID);  // Correctly accessing partnerID
        setLoading(false);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        setLoading(false);
      });
    }
  }, [jwtToken]);

  return { loading, partnerID };
}

export default useFetchIDPartner;
