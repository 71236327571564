import React, { useState } from 'react';
import { TablePartner } from '../../scenes/partner/TablePartner';
import PartnerInfo from '../../userScenes/clients/PartnerInfo';
import Breadcrumb from '../breadcrumb/breadcrumb';

const TAB_GENERAL = 'Geral';
const TAB_PASSWORD = 'Password';

const tabs = [
  { name: TAB_GENERAL, component: <PartnerInfo />, current: true, title: 'Definições' },
  { name: TAB_PASSWORD, component: <TablePartner />, current: false, title: 'Password' },
];

function UserInfo() {
  const [activeTab, setActiveTab] = useState(tabs.find(tab => tab.current).name);

  const handleTabClick = (tab) => {
    setActiveTab(tab.name);
  };

  const pages = [{ name: 'Os seus detalhes', href: '/user/geral', current: true }];

  return (
    <div className="ml-10 mr-10">
      <Breadcrumb pages={pages} />

      <h1 className="text-3xl mt-10 font-bold leading-6 text-gray-900">Informações de conta</h1>

      <div className="hidden mt-10 lg:block sm:px-auto">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                className="w-full h-full text-center"
                onClick={() => handleTabClick(tab)}
              >
                <div
                  className={[
                    tab.name === activeTab
                      ? 'border-green-900 text-green-900'
                      : 'border-transparent text-green-cl cursor-pointer hover:border-gray-300 hover:text-black',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                  ].join(' ')}
                >
                  {tab.name}
                </div>
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="block lg:hidden ">
        <select
          className="form-select w-full text-green-cl bg-white border border-gray-400 focus:border-green-900 hover:border-green-900"
          value={activeTab}
          onChange={(e) => handleTabClick({ name: e.target.value })}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-5 ">
        {tabs.map((tab) => (
          <React.Fragment key={tab.name}>
            {tab.name === activeTab && tab.component}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default UserInfo;
