import { useEffect, useState } from 'react';
import { URL } from '../../service/Service';
export const usePartnerData = (id, jwtToken) => {
  const [data, setData] = useState({
    email: '',
    name: '',
    address: '',
    zipCode: '',
    nif: '',
    notes: '',
    createAt: '',
    url: '',
    rateType: '',
    rateValue: '',
    softwareType: '',
    iban: '',
  });

  useEffect(() => {
    fetch(`${URL}/api/partners/${id}`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Network response was not ok');
      })
      .then((fetchedData) => {
        setData(fetchedData);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [id, jwtToken]);

  return data;
};
