import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import SuccessMessage from "../../components/SuccessMessage";
import ErrorMessage from "../../components/ErrorMessage";
import {URL} from "../../service/Service";

const WithdrawalFormAdmin = () => {
  const [withdrawalAmount, setWithdrawalAmount] = useState({
    recordAmount: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [messageErr, setMessageErr] = useState('');
  const [subtitleErr, setSubtitleErr] = useState('');
  const [balanceData, setBalanceData] = useState({ balance: 0 });
  const [loading, setLoading] = useState(false);
  const jwtToken = sessionStorage.getItem('jwt');

  /* const handleAmountClick = (amount) => {
    setWithdrawalAmount((prevState) => ({
      recordAmount: Number(prevState.recordAmount) + amount,
    }));
  }; */

  const clearErrorMessages = () => {
    setMessageErr('');
    setSubtitleErr('');
  };

  /* const handleInputChange = (event) => {
    const { value } = event.target;
    let parsedValue = parseInt(value);
    if (isNaN(parsedValue)) {
      parsedValue = null;
    }
    setWithdrawalAmount((prevState) => ({
      ...prevState,
      recordAmount: parsedValue,
    }));
  };
  const clearInputField = () => {
    setWithdrawalAmount({ recordAmount: "" });
  }; */

  const id = useParams();

  console.log(id);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (loading) return;

    // Use the current balance if recordAmount is empty
    const amountToWithdraw =
      withdrawalAmount.recordAmount || balanceData.balance;

    // Check if the amount meets the minimum requirement before submission
    if (amountToWithdraw < 50) {
      setMessageErr("O valor mínimo para levantamento é 50,00€");
      return;
    }

    setSuccessMessage("");
    setMessageErr("");
    setSubtitleErr("");
    setLoading(true);
    try {
      // sending data to server
      const response = await fetch(
        `${URL}/api/partners/${id.id}/withdrawals`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ recordAmount: amountToWithdraw }),
        }
      );
      const data = await response.json();
      if (data.success) {
        setSuccessMessage(data.message);
        setWithdrawalAmount({ recordAmount: '' }); // Clear the form after successful submission
        setMessageErr('');
      } else {
        setMessageErr(data.message);
        setSuccessMessage("Pedido de levantamento efetuado com sucesso");
        setTimeout(() => {
          window.location.reload(); // Reload the page after 3 seconds
        }, 3000);
      }
    } catch (error) {
      setMessageErr("Não foi possível pedir o levantamento.");
      setSubtitleErr("Volte a tentar.");
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch balance data
  const fetchBalanceData = async () => {
    try {
      const response = await fetch(`${URL}/api/partners/${id.id}/balance`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setBalanceData(data);
      } else {
        throw new Error("Failed to fetch balance data");
      }
    } catch (error) {
      console.error("Error fetching balance data:", error);
    }
  };

  // Fetch balance data when component mounts
  useEffect(() => {
    fetchBalanceData();
  }, [id]); // Add partnerId as a dependency to re-fetch on change

  return (
    <div>
      <div className="p-6">
        <h1 className="text-md font-bold mb-4">PEDIDO DE LEVANTAMENTO</h1>
        <p className="text-sm -mt-4">
          Apenas levantamentos a partir de 50,00€ serão aceites.{" "}
        </p>
        <form onSubmit={handleSubmit}>
          {successMessage && <SuccessMessage message={successMessage} />}
          {messageErr && (
            <ErrorMessage
              messageErr={messageErr}
              subtitleErr={subtitleErr}
              duration={2000}
            />
          )}
          {/* <div className="flex mt-4 mb-4 flex-col justify-between">
                        <div className="flex ">
                            <input
                                type="number"
                                placeholder="Valor para levantar"
                                min="0"
                                value={withdrawalAmount.recordAmount === 0 ? '' : withdrawalAmount.recordAmount}
                                onChange={handleInputChange}
                                className="bg-gray-200 w-full text-gray-700 border-transparent py-2 px-4 rounded-lg mb-4 placeholder-thin"
                            />
                            <div className="ml-2">
                                <button
                                    type="button"
                                    onClick={clearInputField}
                                    className="bg-gray-200 w-full text-gray-700 py-2 px-4 rounded-lg mb-4 placeholder-thin"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 hover:text-green-cl">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z" />
                                    </svg>

                                </button>

                            </div>
                        </div>
                    </div> 

                    <div className="justify-between flex flex-row -mt-4">
                        <button
                            type="button"
                            key={50}
                            className="bg-gray-200 text-black hover:bg-green-cl hover:text-gray-200 font-bold py-2 px-8 rounded-lg mr-2"
                            onClick={() => handleAmountClick(50)}
                        >
                            50
                        </button>
                        <button
                            type="button"
                            key={100}
                            className="bg-gray-200 text-black hover:bg-green-cl hover:text-gray-200 font-bold py-2 px-8 rounded-lg mx-1"
                            onClick={() => handleAmountClick(100)}
                        >
                            100
                        </button>

                        <button
                            type="button"
                            key={200}
                            className="bg-gray-200 text-black hover:bg-green-cl hover:text-gray-200 font-bold py-2 px-8 rounded-lg mx-2"
                            onClick={() => handleAmountClick(200)}
                        >
                            200
                        </button>
                        <button
                            type="button"
                            key={500}
                            className="bg-gray-200 text-black hover:bg-green-cl hover:text-gray-200 font-bold py-2 px-8 rounded-lg ml-2"
                            onClick={() => handleAmountClick(500)}
                        >
                            500
                        </button>

                    </div> */}

          <div className="flex flex-col mt-4">
            <label className="text-md font-bold mb-2">Saldo </label>
            <div className="bg-gray-200 w-full text-gray-700 border-transparent py-2 px-4 rounded-lg mb-4">
              {withdrawalAmount.recordAmount}
              {(Math.ceil(balanceData.balance * 100) / 100).toFixed(2).replace('.', ',')}€
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <button
              type="submit"
              name="requestWithdrawalAdminButton"

              // This frozes the button when the balance is less than 50.
              className={`w-full text-white font-semibold focus:ring-4 py-2 px-4 rounded-lg ${
                balanceData.balance >= 50
                  ? 'bg-green-cl hover:bg-green-900'
                  : 'bg-gray-400 cursor-not-allowed'
              }`}
              disabled={balanceData.balance < 50}

              // This is a tooltip that appears when the button is disabled and the mouse hovers over it.
              title={
                balanceData.balance < 50
                  ? 'É necessário um saldo mínimo de 50,00€.'
                  : ''
              }
            >
              {loading ? 'A processar...' : 'Pedir levantamento'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WithdrawalFormAdmin;
