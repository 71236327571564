import React, { useState } from 'react';

const DeleteModalPartner = ({ partnerID, handleDelete }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => setIsModalOpen(false);

    const handleDeleteClick = () => {
        handleDelete(partnerID);
        closeModal();
    };

    return (
        <>
                <button onClick={() => setIsModalOpen(true)}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-5"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                    </svg>
                </button>
            {isModalOpen && (
                <div
                    className="flex fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    onClick={closeModal}
                >
                    <div
                        className="relative p-4 m-auto max-w-md bg-white rounded-lg shadow-xl"
                        onClick={(event) => event.stopPropagation()}
                    >
                        <p className="text-lg text-black mb-4">
                            Tem a certeza que quer apagar este parceiro?
                        </p>
                        <div className="flex justify-between">
                            <button
                                className="h-fit w-fit mt-auto border hover:bg-opacity-80 text-green-cl border-green-cl hover:border-green-900 hover:bg-green-900 hover:text-white py-2 px-4 rounded"
                                onClick={closeModal}
                            >
                                Cancelar
                            </button>
                            <button
                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-cl px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-cl focus:outline-none focus:ring-2 hover:opacity-90 focus:ring-offset-2 sm:w-auto sm:text-sm"
                                onClick={handleDeleteClick}
                            >
                                Apagar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};



export default DeleteModalPartner;