import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { URL } from "../../service/Service";

function EachBalance() {
  const [showBalance, setShowBalance] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(true);
  const [balanceData, setBalanceData] = useState({ balance: 0 });
  const [loadingTotal, setLoadingTotal] = useState(false);
  const jwtToken = sessionStorage.getItem("jwt");
  const { id } = useParams(); // Ensure you have partnerId from URL params
console.log(id);
  // Function to fetch balance data
  const fetchBalanceData = async () => {
    try {
      const response = await fetch(`${URL}/api/partners/${id}/balance`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setBalanceData(data);
      } else {
        throw new Error('Failed to fetch balance data');
      }
    } catch (error) {
      console.error('Error fetching balance data:', error);
    } finally {
      setLoadingBalance(false);
    }
  };

  // Function to trigger total calculation
  const triggerTotalCalculation = async () => {
    setLoadingTotal(true);
    try {
      const response = await fetch(`${URL}/api/${id}/triggerTotalCalculation`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      if (response.ok) {
        window.location.reload(); // Refreshing the page after successful trigger (consider a better UX flow)
      } else {
        throw new Error('Failed to trigger total amount calculation');
      }
    } catch (error) {
      console.error('Error triggering total calculation:', error);
    } finally {
      setLoadingTotal(false);
    }
  };

  // Fetch balance data when component mounts
  useEffect(() => {
    fetchBalanceData();
  }, [id]); // Add partnerId as a dependency to re-fetch on change

  // Toggle balance visibility
  const handleToggle = () => {
    const newShowBalance = !showBalance;
    setShowBalance(newShowBalance);
    localStorage.setItem("showBalance", JSON.stringify(newShowBalance));
  };

  return (
    <div className="flex flex-col justify-end sm:flex-row sm:items-center sm:space-x-4 ml-4">
      <div className="relative inline-block text-left">
        <button
          type="button"
          name="ClientBalance"
          className="w-full sm:w-auto rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-cl"
          onClick={handleToggle}
          disabled={loadingBalance} // Disable button when loading balance data
        >
          <div className="flex items-center">
            <div
              className={classNames("text-green-cl font-bold", {
                hidden: showBalance,
              })}
            >
              Saldo
            </div>
          </div>
          {loadingBalance ? (
            <div className="flex items-center justify-center absolute inset-0">
              <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-green-500"></div>
            </div>
          ) : (
            <span className={classNames("font-bold", { hidden: !showBalance })}>
              {balanceData.balance === 0 ? "0€" : `${(Math.ceil(balanceData.balance * 100) / 100).toFixed(2).replace('.', ',')}€`}
              <span className="ml-2 font-light text-gray-400 text-xs">
                {balanceData.balance === 0 ? "(Sem saldo)" : "(Clique para esconder)"}
              </span>
            </span>
          )}
        </button>
      </div>

      <button
        className="mt-4 sm:mt-0 bg-white rounded-md border border-gray-300 shadow-sm px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 flex items-center justify-center"
        onClick={triggerTotalCalculation}
        disabled={loadingTotal} // Disable button when triggering total calculation
      >
        Atualizar saldo
      </button>
    </div>
  );
}

export default EachBalance;
