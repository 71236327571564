import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorMessage from '../../components/ErrorMessage';
import SuccessMessage from '../../components/SuccessMessage';
import { URL } from '../../service/Service';

const CreateRate = ({ button1, button2 }) => {
   const [successMessage, setSuccessMessage] = useState('');
   const [errorMessage, setErrorMessage] = useState('');
   const token = sessionStorage.getItem('jwt');
   const [messageErr, setMessageErr] = useState('');
   const [subtitleErr, setSubtitleErr] = useState('');

   const [rateData, setRateData] = useState({
      nameService: '',
      rateValue: '',
      ratePercentage: '',
      rateType: '',
   });

   const handleChange = (event) => {
      setRateData({
         ...rateData,
         [event.target.name]: event.target.value,
      });

   };

   const { id } = useParams();

   const handleSubmit = async (event) => {
      event.preventDefault();
      setSuccessMessage('');
      setMessageErr('');
      setSubtitleErr('');
      try {
         const response = await fetch(`${URL}/api/partners/${id}/rate`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(rateData),
         });

         const data = await response.json();
         if (data.success) {
            setSuccessMessage(data.message);
            setMessageErr('');
         } else {
            setErrorMessage(data.message);
            setSuccessMessage('Taxa criada com sucesso');
            setTimeout(() => {
               window.location.reload(); // Reload the page after 3 seconds
            }, 2000);
         }
      } catch (error) {
         setMessageErr('Não foi possível pedir o levantamento.');
         setSubtitleErr('Volte a tentar.');
         setSuccessMessage('');
         setTimeout(() => {
            clearErrorMessages();
         }, 3000);
      }
   };

   const clearErrorMessages = () => {
      setMessageErr('');
      setSubtitleErr('');
   };

   return (

      <form className="space-y-4 divide-y divide-gray-200" onSubmit={handleSubmit}>
         <div className="space-y-4 divide-y divide-gray-200">
            {successMessage && <SuccessMessage message={successMessage} />}
            {messageErr && <ErrorMessage messageErr={messageErr} subtitleErr={subtitleErr} duration={2000} />}
            <div>
               <div>
                  <h3 className="text-2xl font-bold uppercase text-gray-900">Criar/Atualizar Taxas</h3>
                  <p className="text-xs text-gray-500">Crie uma nova taxa ou atualize a mesma.</p>
               </div>
            
               <div className="sm:col-span-2 mt-4">
                  <label htmlFor="nameService" className="block text-sm font-medium text-black">
                     Tipo de Serviço
                  </label>
                  <div className="mt-1">
                     <select
                        id="nameService"
                        name="nameService"
                        autoComplete="nameService"
                        className="block w-full rounded-md border border-gray-300 focus:border-green-900 focus:ring-green-900 sm:text-sm bg-white"
                        value={rateData.nameService}
                        onChange={handleChange}
                     >
                        <option value="" disabled>Escolha o método</option>
                        <option value={'MBWay'}>MB Way</option>
                        <option value={'Multibanco'}>Multibanco</option>
                        <option value={"CreditCard"}>Cartão de Crédito</option>
                        <option value={"TPA"}>TPA</option>
                        <option value={"CredoraxTPA"}>Credorax TPA</option>
                        <option value={"DirectDebit"}>Débito Direto</option>
                        <option value={"Bizum"}>Bizum</option>
                        <option value={"Paysafecard"}>Paysafecard</option>
                        <option value={"Santander"}>Santander</option>
                        <option value={"SplitPayment"}>Split Payment</option>
                        <option value={"Payshop"}>Payshop</option>
                        <option value={"Paysafecash"}>Paysafecash</option>
                     </select>
                  </div>
               </div>
               <div className="sm:col-span-2 mt-4">
                  <label htmlFor="rateType" className="block text-sm font-medium text-black">
                     Tipo de Taxa
                  </label>
                  <div className="mt-1">
                     <select
                        id="rateType"
                        name="rateType"
                        autoComplete="rateType"
                        className="block w-full rounded-md border border-gray-300 focus:border-green-900 focus:ring-green-900 sm:text-sm bg-white"
                        value={rateData.rateType}
                        onChange={handleChange}
                     >
                        <option value="" disabled>Escolha o tipo de taxa</option>
                        <option value={'FIXED_RATE'}>Taxa Fixa</option>
                        <option value={'VARIABLE_RATE'}>Taxa Variável</option>
                        <option value={"MIXED_RATE"}>Taxa Mista</option>
                     </select>
                  </div>
               </div>
               {rateData.rateType === 'FIXED_RATE' && (
                  <div className="sm:col-span-6 mt-4">
                     <label htmlFor="rateValue" className="block text-sm font-medium text-gray-700">
                        Valor da Taxa Fixa
                     </label>
                     <div className="mt-1">
                        <input
                           id="rateValue"
                           name="rateValue"
                           type="number"
                           className="block w-full rounded-md border border-gray-300 focus:border-green-900 focus:ring-green-900 sm:text-sm bg-white"
                           value={rateData.rateValue}
                           onChange={handleChange}
                        />
                     </div>
                     <p className="mt-2 text-xs text-gray-500">Escreva informações extras sobre a taxa</p>
                  </div>
               )}
               {rateData.rateType === 'VARIABLE_RATE' && (
                  <div className="sm:col-span-6 mt-4">
                     <label htmlFor="percentageRate" className="block text-sm font-medium text-gray-700">
                        Percentagem da Taxa Variável
                     </label>
                     <div className="mt-1">
                        <input
                           id="ratePercentage"
                           name="ratePercentage"
                           type="number"
                           className="block w-full rounded-md border border-gray-300 focus:border-green-900 focus:ring-green-900 sm:text-sm bg-white"
                           value={rateData.ratePercentage}
                           onChange={handleChange}
                        />
                     </div>
                     <p className="mt-2 text-xs text-gray-500">Escreva informações extras sobre a taxa</p>
                  </div>
               )}
               {rateData.rateType === 'MIXED_RATE' && (
                  <>
                     <div className="sm:col-span-6 mt-4">
                        <label htmlFor="rateValue" className="block text-sm font-medium text-gray-700">
                           Valor da Taxa Fixa
                        </label>
                        <div className="mt-1">
                           <input
                              id="rateValue"
                              name="rateValue"
                              type="number"
                              className="block w-full rounded-md border border-gray-300 focus:border-green-900 focus:ring-green-900 sm:text-sm bg-white"
                              value={rateData.rateValue}
                              onChange={handleChange}
                           />
                        </div>
                        <p className="mt-2 text-xs text-gray-500">Escreva informações extras sobre a taxa fixa</p>
                     </div>
                     <div className="sm:col-span-6 mt-4">
                        <label htmlFor="ratePercentage" className="block text-sm font-medium text-gray-700">
                           Percentagem da Taxa Variável
                        </label>
                        <div className="mt-1">
                           <input
                              id="ratePercentage"
                              name="ratePercentage"
                              type="number"
                              className="block w-full rounded-md border border-gray-300 focus:border-green-900 focus:ring-green-900 sm:text-sm bg-white"
                              value={rateData.ratePercentage}
                              onChange={handleChange}
                           />
                        </div>
                        <p className="mt-2 text-xs text-gray-500">Escreva informações extras sobre a taxa variável</p>
                     </div>
                  </>
               )}
            </div>
            {button1}

            {button2}
         </div>
      </form>

   );
};

export default CreateRate;
