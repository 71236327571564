import {faLandmarkFlag} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ExclamationCircleIcon} from '@heroicons/react/20/solid';
import {CreditCardIcon, XMarkIcon} from '@heroicons/react/24/outline';
import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Pagination from '../../components/pagination/Pagination';
import ModalAll from '../../scenes/ModalAll';
import {URL} from '../../service/Service';
import AccountBalance from '../AccountBalance';
import {Withdrawal} from '../Withdrawal';
import useFetchIDPartner from '../useFetchIDPartner';
import WithdrawalCancel from './WithdrawalCancel';

export const Balance = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [originalPeople, setOriginalPeople] = useState([]);
	const [peoplePerPage] = useState(5);
	const jwtToken = sessionStorage.getItem('jwt');
	const [error, setError] = useState(null);
	const [errorMessage, setErrorMessage] = useState("");

	const {loading, partnerID} = useFetchIDPartner(jwtToken);
	const [peopleData, setPeopleData] = useState(originalPeople);
	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const pages = [{name: 'Gestão de Transações', href: '/user/levantamento', current: true}];
	const [data, setData] = useState({
		status: 'PENDING'
	});

	const updateHandler = (person) => {
		console.log(person.recordID);

		fetch(`${URL}/api/partners/${partnerID}/records/${person.recordID}`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${jwtToken}`,
			},
			body: JSON.stringify(data),
		})
			.then((response) => {
				if (response.ok) {
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				}
			})
			.catch((error) => {
				// handle error
			});
	};

	const [showPulse, setShowPulse] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowPulse(false);
		}, 6000);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		if (!loading && partnerID) {
			const getClients = async () => {
				try {
					const res = await fetch(`${URL}/api/partners/${partnerID}/records`, {
						headers: {
							'Authorization': `Bearer ${jwtToken}`,
							'Content-Type': 'application/json'
						}
					});
					const data = await res.json();
					setOriginalPeople(data.reverse());
					setPeopleData(data);

				} catch (error) {
					setError(error);
				}
			};

			getClients();
		}
	}, [loading, partnerID, setError, jwtToken]);

	// Get current people
	const indexOfLastPerson = currentPage * peoplePerPage;
	const indexOfFirstPerson = indexOfLastPerson - peoplePerPage;
	const currentPeople = peopleData.slice(indexOfFirstPerson, indexOfLastPerson);

	// Change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	function getStatusClass(recordStatus) {
		const lastRecordStatus = recordStatus[recordStatus.length - 1];
		let statusClass;
		if (lastRecordStatus.includes('COMPLETED')) {
			statusClass = 'bg-green-100 text-green-800';
		} else if (lastRecordStatus.includes('PENDING')) {
			statusClass = 'bg-yellow-100 text-yellow-800';
		} else if (lastRecordStatus.includes('CANCELED')) {
			statusClass = 'bg-red-100 text-red-800';
		} else if (lastRecordStatus.includes('REFUNDED')) {
			statusClass = 'bg-orange-100 text-orange-800';
		} else {
			statusClass = 'bg-gray-100 text-gray-800';
		}
		return statusClass;
	}

	const handleOpenWithdrawalModal = () => {
		const hasPendingWithdrawal = peopleData.some((person) =>
			person.recordStatus[person.recordStatus.length - 1].includes("PENDING")
		);

		if (hasPendingWithdrawal) {
			setErrorMessage(
				"É necessário finalizar os pedidos de levantamento pendentes antes de realizar um novo pedido."
			);
			return false; // Block modal from opening
		} else {
			setErrorMessage("");
			return true; // Allow modal to open
		}
	};

	return (
		<div className="ml-4 mr-4 sm:ml-10 sm:mr-10">
			<div className="flex flex-col sm:flex-row sm:justify-between sm:items-center justify-between items-center">
				<Breadcrumb pages={pages}/>
				<AccountBalance/>
			</div>
			<div className="sm:flex sm:items-center mt-4 sm:mt-10">
				<div className="sm:flex-auto">
					<h1 className="text-2xl font-extrabold text-gray-900">
						GESTÃO DE TRANSAÇÕES MONETÁRIAS
					</h1>
					<p className="-mt-1 text-sm text-gray-700">
						Todas as suas transações sobre o seu saldo encontram-se aqui.
					</p>
				</div>
				<div className="relative mt-4 sm:mt-0 sm:ml-4 sm:flex-none">
					<ModalAll
						isOpen={isModalOpen}
						onClose={handleModalClose}
						nameButton="Levantar Dinheiro"
						onClick={handleOpenWithdrawalModal}
					>
						<Withdrawal/>
					</ModalAll>
					{/* Error message if there's a pending withdrawal and you try to withdraw again.*/}
					{errorMessage && (
						<p
							className="absolute top-full mt-2 bg-red-100 border border-red-200 text-red-600 text-sm p-2 rounded-md shadow-md z-10"
							style={{
								left: window.innerWidth < 768 ? "0" : "-200px",
								right: window.innerWidth >= 768 ? "32px" : "auto",
								maxWidth: "calc(100vw - 32px)",
								padding: "16px",
							}}
						>
							{errorMessage}
						</p>
					)}
				</div>
			</div>
			<div className="border-l-4 mt-4 border-yellow-400 bg-yellow-50 p-4">
				<div className="flex">
					<div className="flex-shrink-0">
						<ExclamationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true"/>
					</div>
					<div className="ml-3">
						<p className="text-sm text-yellow-700">
							Se pretender cancelar um pedido de levantamento, surgirá um botão apenas no{' '}
							<strong>
								<span className="italic">estado pendente</span>
							</strong>
							, podendo cancelar ao clicar no{' '}
							<span className="inline-flex items-center">
                ícone <XMarkIcon className="w-5 h-5 flex items-center justify-between ml-1 text-red-900"/>
              </span>{' '}
							que aparecerá na tabela.
						</p>
					</div>
				</div>
			</div>
			<div
				name='TransactionTableDiv'
				className="mt-8">
				<div className="-my-2 -mx-4 sm:-mx-0 md:-mx-6 lg:-mx-8">
					<div className="min-w-full md:px-6 lg:px-8">
						<div className="overflow-x-auto">
							<table
								name='TransactionTable'
								className="w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
								<tr>
									<th className="py-3.5   text-left"><span className="ml-6">ID de Pedido</span></th>
									<th className="py-3.5   text-left">Tipo de Pedido</th>
									<th className="py-3.5   text-left">Valor da transação</th>
									<th className="py-3.5   text-left">Status</th>
									<th className="py-3.5   text-left">Saldo Atual</th>
									<th className="py-3.5   text-left">
										<span className="sr-only">Edit</span>
									</th>
								</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
								{currentPeople
									.filter(person => person.recordAmount !== 0) // Filter out transactions with 0 euros
									.map((person, index) => (
										<tr
											key={`${person.recordID}-${person.recordDate}`}
											className={
												person.recordStatus[person.recordStatus.length - 1].split(' ')[0] === 'PENDING' && showPulse
													? 'bg-opacity-40 animate-pulse transition-colors bg-yellow-200'
													: ''
											}
										>
											<td className="py-4 sm:pl-6 text-left">
												<div className="flex items-left justify-left">
													<div className="h-10 w-10 flex-shrink-0">
														{person.recordType === 'WITHDRAWAL' ? (
															<FontAwesomeIcon className="h-10 w-6 flex-shrink-0"
																			 icon={faLandmarkFlag}/>
														) : (
															<CreditCardIcon className="h-10 w-6 flex-shrink-0"
																			icon={faLandmarkFlag}/>
														)}
													</div>
													<div className="inline-flex flex-col">
														<div className="text-gray-900 px-2">{person.recordID}</div>
														<div className="text-gray-500 px-2">{person.recordDate}</div>
													</div>
												</div>
											</td>
											<td className="py-4 text-black text-left">
                        <span
							className="inline-flex rounded-full bg-gray-300 bg-opacity-60 font-semibold leading-5 px-3 text-black mx-auto">
                          {person.recordType === 'WITHDRAWAL' ? 'Levantamento' : 'Depósito'}
                        </span>
											</td>
											<td className="py-4 text-black text-left">
												{person.recordType.includes('WITHDRAWAL') ? '-' : '+'} {(Math.ceil(person.recordAmount * 100) / 100).toFixed(2).replace('.', ',')} €
											</td>
											<td className="py-4 text-black text-left">
                        <span
							className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${getStatusClass(
								person.recordStatus
							)}`}
						>
                          {person.recordStatus[person.recordStatus.length - 1].split(' ')[0]}
                        </span>
											</td>
											<td className="py-4 text-black text-left">
												{(Math.ceil(person.balance * 100) / 100).toFixed(2).replace('.', ',')} €
											</td>
											<td className="py-4 text-left">
												{/*  <ModalAll icon={<DocumentDuplicateIcon className="w-5 h-5" />}>
                          <RequestsHistoric
                            recordStatus={currentPeople[index].recordStatus}
                            recordId={currentPeople[index].recordId}
                            recordAmount={currentPeople[index].recordAmount}
                          />
                        </ModalAll> */}
												{person.recordStatus[person.recordStatus.length - 1].split(' ')[0] === 'PENDING' && (
													<ModalAll
														icon={
															<div className="relative">
																<XMarkIcon className="w-5 h-5 text-red-900"/>
																{showPulse && (
																	<div
																		className="absolute inset-0 rounded-full ring-2 ring-yellow-600 animate-ping">
																	</div>
																)}
															</div>
														}
													>
														<WithdrawalCancel person={person}
																		  updateHandler={updateHandler}/>
													</ModalAll>
												)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
							<Pagination
								postsPerPage={peoplePerPage}
								totalPosts={peopleData.length}
								paginate={paginate}
								currentPage={currentPage}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
