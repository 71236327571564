import React, { Fragment, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline';
import { URL } from '../../service/Service';

const LogoutModal = ({ isOpen }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const cancelButtonRef = useRef();
  const navigate = useNavigate();

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    // Perform logout logic
    await logoutUser();

    // Close the sidebar if it was open
    if (isOpen) {
      isOpen(false);
    }
  };


  const logoutUser = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${URL}/api/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          setError('Your session has expired. Please log in again.');
        } else {
          setError('Failed to log out. Please try again later.');
        }
      } else {
        handleLogoutSuccess();
      }
    } catch (error) {
      console.error(error);
      setError('Failed to log out. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogoutSuccess = () => {
    sessionStorage.removeItem('jwt');
    navigate('/');
    setOpen(false);
  };

  return (
    <>
     <button
  onClick={handleModalOpen}
  className="group p-4 flex text-sm items-center text-gray-300 hover:text-blue-leve flex-grow w-full h-full cursor-pointer"
>
  <FontAwesomeIcon
    icon={faSignOutAlt}
    className="mr-6 text-gray-300 hover:text-gray-300 flex-shrink-0 h-5 w-6"
  />
  <span className="mr-6 text-gray-300 hover:text-blue-leve">
    {isOpen ? 'Terminar Sessão' : null}
  </span>
</button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={handleModalClose}
        >
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ArrowLeftCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-extrabold text-black"
                    >
                      Terminar sessão na Plataforma de Parceiros
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Tem a certeza de que deseja terminar sessão? Será redirecionado para a página de início de sessão.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-cl text-base font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 hover:opacity-90 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleLogout}
                  >
                    {isLoading ? 'A sair...' : 'Terminar sessão'}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-cl focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={handleModalClose}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default LogoutModal;
