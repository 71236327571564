import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Pagination from '../../components/pagination/Pagination';
import DeleteModalPartner from './DeleteModalPartner';
import { URL } from '../../service/Service';
import Logout from '../login/Logout';

export const TablePartner = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [originalPeople, setOriginalPeople] = useState([]);
  const [peopleData, setPeopleData] = useState(originalPeople);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const { id } = useParams();
  const pages = [{ name: 'Lista de Parceiros', href: '/admin/parceiros', current: true }];
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getPartners = async () => {
      const jwtToken = sessionStorage.getItem("jwt");
      try {
        const res = await axios.get(`${URL}/api/partners`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        setOriginalPeople(res.data);
        setPeopleData(res.data);
      } catch (error) {
        console.error(error);
        setError(error);
      }
    };

    getPartners();
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = peopleData.slice(indexOfFirstPost, indexOfLastPost);

  const handleChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    let filteredData = originalPeople;
    if (searchTerm) {
      filteredData = originalPeople.filter((data) => {
        return (
          data.name.toLowerCase().includes(searchTerm) ||
          data.nif.toLowerCase().includes(searchTerm)
        );
      });
    }

    setPeopleData(filteredData);
  };

  const handleDelete = async (partnerID) => {
    const jwtToken = sessionStorage.getItem("jwt");
    try {
      await axios.delete(`${URL}/api/partners/${partnerID}`, {
        headers: {
          'Authorization': `Bearer ${jwtToken}`
        }
      });
      setPeopleData(prevPeopleData => prevPeopleData.filter(person => person.partnerID !== partnerID));
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container fluid className="mt-6 mx-10">
      <Row className="justify-between items-center mb-6">
        <Col>
          <Breadcrumb pages={pages} />
        </Col>
       {/*  <Col>
          <Logout />
        </Col> */}
      </Row>

      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-3xl font-black text-gray-900">PARCEIROS</h1>
          <p className="mt-2 text-sm text-gray-700">
            Uma lista de todos os parceiros existentes, incluindo algumas informações como o nome, email, website, papel e nif.
          </p>
        </div>
        <div>
          <Link to="/admin/criar-parceiro">
            <button
              type="button"
              className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-cl rounded-md shadow-sm hover:bg-green-eupago focus:outline-none focus:ring-2 focus:ring-green-cl focus:ring-offset-2"
            >
              Novo parceiro
            </button>
          </Link>
        </div>
      </div>

      <Row className="mb-6">
        <Col>
          <Form.Control
            type="text"
            placeholder="Pesquisar por Nome ou Nif"
            className="bg-white border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
            onChange={handleChange}
            value={searchTerm}
          />
        </Col>
      </Row>

      <Row className="rounded-lg overflow-hidden">
        <Col>
          <div className="overflow-x-auto">
            <table className="w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
  <tr>
    <th scope="col" className="px-4 py-3 text-xs font-semibold text-gray-900 text-left"> {/* Added text-left class */}
      {/* Your content */}
    </th>
    <th scope="col" className="px-4 py-3 text-xs font-semibold text-gray-900 text-left"> {/* Added text-left class */}
      Name
    </th>
    <th scope="col" className="px-4 py-3 text-xs font-semibold text-gray-900 text-left"> {/* Added text-left class */}
      Email
    </th>
    <th scope="col" className="px-4 py-3 text-xs font-semibold text-gray-900 text-left"> {/* Added text-left class */}
      NIF
    </th>
    <th scope="col" className="px-4 py-3 text-xs font-semibold text-gray-900 text-left"> {/* Added text-left class */}
      <span className="sr-only">Edit</span>
    </th>
  </tr>
</thead>

              <tbody className="bg-white divide-y divide-gray-200 text-xs">
                {currentPosts.map((person) => (
                  <tr key={person.partnerID}>
                    <td className="px-2 py-3 whitespace-nowrap">
                      <div className="flex items-center">
                        <DeleteModalPartner
                          partnerID={person.partnerID}
                          handleDelete={handleDelete}
                        />
                      </div>
                    </td>
                    <td className="px-2 py-3 whitespace-nowrap text-sm ">
                      <div className="flex items-center">
                        <div className="text-black">{person.name}</div>
                      </div>
                    </td>
                    <td className="px-2 py-3 whitespace-nowrap text-sm">
                      <div className="text-gray-900">{person.email}</div>
                    </td>
                    <td className="px-2 py-3 whitespace-nowrap text-sm">{person.nif}</td>
                    <td className="px-2 py-3 whitespace-nowrap text-sm">
                      <div className="flex justify-end items-center">
                        <button
                          onClick={() => { window.location.href = `/admin/parceiros/${person.partnerID}/edit`; }}
                          className="text-white flex items-center border-none bg-green-cl rounded-md px-2 py-2 cursor-pointer"
                        >
                          <span >Ver mais</span>
                       {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                            <path
                              fillRule="evenodd"
                              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                              clipRule="evenodd"
                            />
                          </svg> */}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>

      <Row className="mt-6">
        <Col>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={peopleData.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default TablePartner;
