import React, { useEffect, useState } from 'react';
import { URL } from '../../service/Service';
import { useFetchAdmin } from '../hooks/fetchName';
import ModalSettings from './ModalSettings';

const API_URL = `${URL}/api`;

export const Settings = () => {

    const adminName = useFetchAdmin();
    const jwtToken = sessionStorage.getItem('jwt');

    const [error, setError] = useState(null);

    const [data, setData] = useState({ email: '', name: '' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_URL}/getAdmin`, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                });

                if (response.ok) {
                    const fetchedData = await response.json();
                    setData(fetchedData);
                } else {
                    throw new Error('Network response was not ok');
                }
            } catch (error) {
                setError(error.message);
            }
        };

        fetchData();
    }, [jwtToken]);
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <div className="overflow-hidden mt-6 bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6 flex items-center justify-between">
                <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900"> Informações de conta</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Detalhes pessoais e alteração de password.</p>
                </div>
                <div>
                    <button className="bg-green-cl text-white px-4 py-2 rounded-lg" onClick={handleOpenModal}>Alterar Password</button>
                    <ModalSettings open={modalOpen} setOpen={handleCloseModal} />
                </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Nome</dt>
                        <dd className="mt-1 text-sm text-gray-900">{adminName?.name}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Email</dt>
                        <dd className="mt-1 text-sm text-gray-900">{adminName?.email}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Tipo de Conta</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {adminName?.role === "ADMIN"
                                ? "Administrador"
                                : adminName?.role === "MANAGER"
                                    ? "Gestor"
                                    : adminName?.role === "PARTNER"
                                        ? "Parceiro"
                                        : "Utilizador Desconhecido"}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Data de criação</dt>
                        <dd className="mt-1 text-sm text-gray-900">{adminName?.createAt}</dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}
