import React, { useEffect, useState } from 'react';
import { URL } from '../../service/Service';
import useFetchIDPartner from '../useFetchIDPartner';
import AccountBalance from '../AccountBalance';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';

export default function ActiveRatesUser() {
  const [rates, setRates] = useState([]);
  const [error, setError] = useState(null);
  const jwtToken = sessionStorage.getItem('jwt');
  const pages = [{ name: 'Taxas Ativas', href: '/user/taxas', current: true }];
  const { loading, partnerID } = useFetchIDPartner(jwtToken);

  useEffect(() => {
    if (!loading && partnerID) {
      const getClients = async () => {
        try {
          const res = await fetch(`${URL}/api/partners/${partnerID}/rates`, {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              'Content-Type': 'application/json',
            },
          });
          if (res.ok) {
            const data = await res.json();
            setRates(data);
          } else {
            throw new Error('Failed to fetch data');
          }
        } catch (error) {
          setError(error);
        }
      };

      getClients();
    }
  }, [loading, partnerID, jwtToken]);

  return (
    <div className="mx-4 sm:mx-10">
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center justify-between items-center">
        <Breadcrumb pages={pages} />
        <AccountBalance />
      </div>

      <div className="py-6">
        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          Taxas atualmente ativas
        </h1>
        <p className="mt-2 text-sm text-gray-500">Verifique as taxas atualmente ativas.</p>
      </div>

      {rates.length === 0 ? (
        <div className="text-center text-gray-500">
          <p>Não há taxas ativas no momento.</p>
          {/* You can add more information or call to action here */}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {rates.map((rate) => (
            <div key={rate.id} className="bg-white rounded-lg shadow-md hover:shadow-lg">
              <div className="px-6 py-6">
                <div className="flex items-center mb-4">
                  <h3 className="text-lg font-medium text-gray-900">
                    {rate.nameService === "CreditCard" ? "Cartão de Crédito" : rate.nameService === "DirectDebit" ? "Débito direto" : rate.nameService === "CredoraxTPA" ? "Credorax TPA" : rate.nameService}
                  </h3>
                </div>

                <div className="text-sm font-bold text-green-cl mb-2">
                  {rate.rateType === 'FIXED_RATE' && 'Taxa Fixa'}
                  {rate.rateType === 'VARIABLE_RATE' && 'Taxa Variável'}
                  {rate.rateType === 'MIXED_RATE' && 'Taxa Mista (Fixa e Variável)'}
                </div>
                <div className="flex items-center text-2xl font-bold text-green-900">
                  {rate.rateType === 'FIXED_RATE' && (
                    <>
                      {rate.rateValue.toLocaleString('pt-PT', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}€
                    </>
                  )}

                  {rate.rateType === 'VARIABLE_RATE' && (
                    <>
                      {rate.ratePercentage}%
                    </>
                  )}

                  {rate.rateType === 'MIXED_RATE' && (
                    <>
                      {rate.rateValue.toLocaleString('pt-PT', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                      <span className="mr-1">€</span>
                      <span className="mx-1">+</span>
                      {rate.ratePercentage}%
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
