import { PencilIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import Pagination from "../../components/pagination/Pagination";
import EditClientModal from "../../scenes/clients/EditClientModal"; // Import the new modal component
import { URL } from "../../service/Service";
import AccountBalance from "../AccountBalance";
import useFetchIDPartner from "../useFetchIDPartner";
import jwtDecode from 'jwt-decode';
import EditClientModalUser from "./EditClientModalUser";
import axios from 'axios';

export const Table = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [originalPeople, setOriginalPeople] = useState([]);
  const [peopleData, setPeopleData] = useState(originalPeople);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const jwtToken = sessionStorage.getItem("jwt");

  const role = jwtDecode(jwtToken).authorities[0].authority;
  console.log(role);
  const pages = [
    { name: "Lista de Clientes", href: "/user/clientes", current: true },
  ];

  const { loading, partnerID } = useFetchIDPartner(jwtToken);
  console.log(partnerID);
  const [openClientID, setOpenClientID] = useState(null);
  const [editClientID, setEditClientID] = useState(null); // New state for edit modal

  useEffect(() => {
    if (!loading && partnerID) {
      const getClients = async () => {
        try {
          const res = await fetch(
            `${URL}/api/partners/${partnerID}/costumers/`,
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
                "Content-Type": "application/json",
              },
            }
          );
          const data = await res.json();
          setPeopleData(data);
          setOriginalPeople(data); // Armazena os dados originais para pesquisa
        } catch (error) {
          setError(error);
        }
      };

      getClients();
    }
  }, [loading, partnerID, setError, jwtToken]);

  const handleEdit = (customerID) => {
    setEditClientID(customerID); // Open edit modal with the selected customer ID
  };


  const handleStatusChange = async (customerID, newStatus) => {
    try {
      const response = await axios.patch(
        `${URL}/api/partners/${partnerID}/customer/${customerID}`,
        { customerStatus: newStatus },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
  
      if (response.status === 200) {
        // Atualize o status localmente após a atualização bem-sucedida
        setPeopleData(peopleData.map(person =>
          person.customerID === customerID ? { ...person, customerStatus: newStatus } : person
        ));
      } else {
        console.error("Failed to update customer status");
      }
    } catch (error) {
      console.error("Error updating customer status:", error);
    }
  };
  

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = peopleData.slice(indexOfFirstPost, indexOfLastPost);
  console.log(currentPosts);

  const handleChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filteredData = originalPeople.filter((data) => {
      return data.name.toLowerCase().includes(searchTerm);
    });

    setPeopleData(filteredData);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getStatusColor = (status) => {
    switch (status) {
      case "APPROVED":
        return "text-green-500 bg-green-100 font-bold";
      case "PENDING":
        return "text-yellow-500 bg-yellow-100 font-bold";
      case "REJECTED":
        return "text-red-500 bg-red-100 font-bold";
      default:
        return "";
    }
  };

  return (
    <div className="ml-10 mr-10">
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center justify-between items-center">
        <Breadcrumb pages={pages} />
        <AccountBalance />
      </div>
      <div className="sm:flex sm:items-center mt-10">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-black text-gray-900">CLIENTES</h1>
          <p className="mt-2 text-sm text-gray-700">
            Uma lista de todos os seus clientes registados, incluindo algumas
            informações como o nome, email, website, papel e nif.
          </p>
        </div>
      </div>
      <div className="mt-8">
        <input
          type="search"
          placeholder="Pesquisar por Nome ou Nif"
          className="outline-none bg-white border-gray-300 hover:border-green-700 focus:ring-green-900 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
          onChange={handleChange}
          value={searchTerm}
        />
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-0 md:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      ID de Cliente
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Nome
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Website
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Notas
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {/* Actions */}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {currentPosts.map((person) => (
                    <tr key={person.customerID}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm sm:pl-6">
                        <div className="text-black">{person.customerID}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-black">
                        <div className="text-gray-900">{person.name}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-black">
                        {person.site ? (
                          <a
                            href={person.site}
                            className="hover:text-green-800"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {person.site}
                          </a>
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-black">
                        {person.notes ? person.notes : "-"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                      <div className="relative">
  <select
    value={person.customerStatus}
    onChange={(e) => handleStatusChange(person.customerID, e.target.value)}
    className={`block w-full px-3 py-2 pr-10 text-sm font-medium rounded-md border border-gray-300 bg-white text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${getStatusColor(person.customerStatus)} transition duration-150 ease-in-out`}
  >
    <option value="PENDING">Pendente</option>
    <option value="APPROVED">Aprovado</option>
    <option value="REJECTED">Rejeitado</option>
  </select>
 
</div>


                      </td>
                      <td className="whitespace-nowrap text-sm sm:pl-6">
                        <div className="flex items-center">
                          <button
                            onClick={() => handleEdit(person.customerID)}
                            className="text-green-cl hover:text-green-cl mr-2"
                          >
                            <PencilIcon className="h-5 w-5" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={peopleData.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
      {editClientID && (
        <EditClientModalUser
          customerID={editClientID}
          partnerID={partnerID}
          onClose={() => setEditClientID(null)}
        />
      )}
    </div>
  );
};