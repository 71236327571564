import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { URL } from '../../service/Service';
import { UserContext } from './UserContext';
import eup from './eup.png';

export default function Login() {
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email) {
      setUserEmail(email);
      setRememberMe(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const loginResponse = await axios.post(`${URL}/api/authenticate`, {
        userEmail: userEmail,
        userPassword: userPassword,
      });
      
            const decoded = jwtDecode(loginResponse.data);
            const role = decoded.authorities[0].authority;
            
      sessionStorage.setItem("userRole", role);
      sessionStorage.setItem('jwt', loginResponse.data);

      if (rememberMe) {
        localStorage.setItem('email', userEmail);
      }
      setUser(decoded);

      if (role === 'ADMIN' || role === 'MANAGER') {
        navigate('/admin/dashboard');
      } else if (role === 'PARTNER') {
        navigate('/user/dashboard');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Password expirada. Foi enviado um email para atualizar a sua password.");

        // Delay redirection or display message for 5 seconds
        setTimeout(() => {
          navigate('/resetpassword'); // Redirect to reset password page
        }, 5000); // 5000 milliseconds = 5 seconds
      } else {
        setError("Email ou Password inválida");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex mx-auto min-h-screen h-screen items-center justify-center bg-green-cl sm:px-6 lg:px-8">
      <div className="w-full max-w-md">
        <div className="bg-white py-12 px-6 shadow sm:rounded-lg">
          <div className="mx-auto">
            <img className="mx-auto h-16 w-auto" src={eup} alt="Eu pago" />
          </div>

          <form className="mt-6 space-y-6" onSubmit={handleSubmit}>
            {/* Email input */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-black">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  className="block w-full appearance-none rounded-md border text-black border-green-cl border-opacity-60 px-3 py-2 shadow-sm focus:ring-green-cl focus:outline-none focus:border-green-900 sm:text-sm"
                  placeholder="exemplo@dominio.com"
                />
              </div>
            </div>

            {/* Password input */}
            <div className="relative">
              <label htmlFor="password" className="block text-sm font-medium text-black">
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  required
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                  className="block w-full appearance-none rounded-md border text-black border-green-cl border-opacity-60 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-green-900 sm:text-sm"
                  placeholder="Introduza a password"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute top-5 right-2 h-5 w-7 transform -translate-y-1/2 flex items-center justify-center text-green-cl rounded-full"
                  aria-label="Toggle Password Visibility"
                >
                  {showPassword ? (
                    <EyeIcon className="text-green-cl h-5 w-5" />
                  ) : (
                    <EyeSlashIcon className="text-green-cl h-5 w-5" />
                  )}
                </button>
              </div>
            </div>

            {/* Remember me and forgot password */}
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember_me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-green-cl text-green-cl border-opacity-60 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-green-900"
                  onChange={() => setRememberMe(!rememberMe)}
                  checked={rememberMe}
                />
                <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                  Lembrar-me
                </label>
              </div>
              <div className="text-sm leading-5">
                <a
                  href="/resetpassword"
                  className="font-medium text-green-cl hover:font-bold focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Esqueceu a palavra-passe?
                </a>
              </div>
            </div>

            {/* Login button */}
            <div>
              <span className="block w-full rounded-md shadow-sm">
                <button
                  type="submit"
                  disabled={loading} // Disable button while loading
                  className={`w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md ${
                    loading ? "bg-green-200 text-green-cl" : "bg-green-cl hover:bg-black text-white"
                  } focus:outline-none focus:shadow-outline-md`}
                >
                  {loading ? (
                    <div className="flex items-center">
                      <svg
                        className="animate-spin-slow h-5 w-5 text-green-cl mr-2" // Spinner animation
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      A iniciar sessão...
                    </div>
                  ) : (
                    "Entrar"
                  )}
                </button>
              </span>
            </div>

            {/* Error message */}
            {error && <div className="text-red-500 text-sm">{error}</div>}
          </form>
        </div>
      </div>
    </div>
  );
}
