import React, { useState, useEffect } from "react";
import axios from "axios";
import { URL } from "../../service/Service";

const NotificationsPartner = ({ userId }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentTab, setCurrentTab] = useState("APPROVED"); // Set initial tab to "APPROVED"

  const fetchNotifications = async () => {
    try {
      const jwtToken = sessionStorage.getItem("jwt");
      const response = await axios.get(`${URL}/api/user/notifications`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setNotifications(response.data);
      setLoading(false);
      console.log(response.data);
    } catch (error) {
      console.error(error);
      setError("Falha ao carregar as notificações.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  // Define status groups including both customerStatus and customerStatusUpdate
  const statusGroups = {
    APPROVED: ["APPROVED", "UPDATE_APPROVED"],
    REJECTED: ["REJECTED", "UPDATE_REJECTED"],
  };

  // Determine the actual status to filter by
  const getStatusToFilter = (notification) => {
    if (notification.metadata.customerStatusUpdate) {
      return notification.metadata.customerStatusUpdate;
    }
    return notification.metadata.customerStatus;
  };

  // Filter notifications based on the current tab
  const filteredNotifications = notifications.filter((notification) =>
    statusGroups[currentTab].includes(getStatusToFilter(notification))
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <p className="text-gray-500">A carregar...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-full">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="p-4 m-4 mt-20 ml-6">
      <h2 className="text-xl font-semibold mb-6">Notificações</h2>
      <div className="mb-4">
        <nav className="flex space-x-4">
          <button
            className={`px-4 py-2 rounded-md ${
              currentTab === "APPROVED"
                ? "text-green-500 bg-green-100"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => setCurrentTab("APPROVED")}
          >
            Aprovadas
          </button>
          <button
            className={`px-4 py-2 rounded-md ${
              currentTab === "REJECTED"
                ? "text-red-500 bg-red-100"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => setCurrentTab("REJECTED")}
          >
            Rejeitadas
          </button>
        </nav>
      </div>
      <div className="space-y-4">
        {filteredNotifications.length > 0 ? (
          filteredNotifications.map((notification) => (
            <div
              key={notification.notificationID}
              className="bg-white p-6 rounded-lg shadow-lg flex items-start space-x-4"
            >
              <div className="flex-1">
                <div className="mb-2">
                  <span
                    className={`text-sm font-semibold ${
                      getStatusToFilter(notification) === "APPROVED" ||
                      getStatusToFilter(notification) === "UPDATE_APPROVED"
                        ? "text-green-700"
                        : "text-red-700"
                    }`}
                  >
                    {getStatusToFilter(notification) === "APPROVED" || getStatusToFilter(notification) === "UPDATE_APPROVED"
                      ? `Pedido de ${getStatusToFilter(notification) === "APPROVED" ? "criação" : "atualização"} do cliente `
                      : `Rejeitado ${getStatusToFilter(notification) === "REJECTED" ? "pedido de criação" : "pedido de atualização"} do cliente `}
                    <span className="font-semibold">
                      {notification.metadata.customerName}
                    </span>
                  </span>
                  <span className="block text-gray-900">
                    Pedido efetuado por{" "}
                    <strong>{notification.metadata.partnerName}</strong>
                  </span>
                  <span className="block text-sm text-gray-600">
                    {notification.createAt}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-600">Nenhuma notificação disponível.</p>
        )}
      </div>
    </div>
  );
};

export default NotificationsPartner;
