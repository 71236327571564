import { Dialog, Transition } from "@headlessui/react";
import {
  Bars2Icon,
  CogIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import eupay from "../../scenes/login/assets/logo-dark.png";
import LogoutModal from "../login/Logout";
import axios from "axios";
import { URL } from "../../service/Service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sb({
  sidebarOpens,
  setSidebarOpens,
  opens,
  onToggles,
}) {
  const [current, setCurrent] = useState(0);
  const navigate = useNavigate();
  const [unreadCount, setUnreadCount] = useState(0); // State for unread notifications count

  const navigation = useMemo(
    () => [
      {
        name: "Dashboard",
        href: "/admin/dashboard",
        icon: HomeIcon,
      },
      {
        name: "Parceiros",
        href: "/admin/parceiros",
        icon: UsersIcon,
      },
      {
        name: "Gestores",
        href: "/admin/admins",
        icon: (props) => <FontAwesomeIcon icon={faBriefcase} {...props} />,
      },
      {
        name: "Definições",
        href: "/admin/geral",
        icon: CogIcon,
      },
    ],
    []
  );

  useEffect(() => {
    const currentPath = window.location.pathname;
    const index = navigation.findIndex((item) => item.href === currentPath);
    setCurrent(index);
  }, [navigation]);

  useEffect(() => {
    const fetchUnreadNotifications = async () => {
      try {
        const jwtToken = sessionStorage.getItem("jwt");
        const response = await axios.get(`${URL}/api/user/unread`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        setUnreadCount(response.data);
      } catch (error) {
        console.error("Error fetching unread notifications count:", error);
      }
    };
    fetchUnreadNotifications();
  }, []);
  return (
    <>
      <div>
        <Transition.Root show={sidebarOpens} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex lg:hidden"
            onClose={setSidebarOpens}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gradient-to-r from-blue-green-dark fixed-10 to-blue-green-dark">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    {sidebarOpens && (
                      <button
                        className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpens(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    )}
                  </div>
                </Transition.Child>
                <div className="flex gap-x-4 items-center"></div>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto mt-2"
                    src="https://www.eupago.pt/images/logo-light-normal.webp"
                    alt="Your Company"
                  />
                </div>
                <nav className="py-4">
                  {navigation.map((item, index) => (
                    <div
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        "group flex p-2 text-sm items-center text-gray-300 hover:bg-light-white flex-grow cursor-pointer",
                        current === index
                          ? "bg-green-cl text-white font-bold"
                          : "hover:bg-green-cl hover:bg-opacity-40 hover:text-blue-leve",
                        current === index ? "border-l-4 border-blue-leve" : ""
                      )}
                      onClick={() => {
                        if (item.component) {
                          // If the menu item has a component, render the component
                        } else {
                          // If the menu item does not have a component, navigate to the corresponding href
                          navigate(item.href);
                          setCurrent(index);
                          setSidebarOpens(false);
                        }
                      }}
                    >
                      <item.icon
                        className={classNames(
                          current === index
                            ? "text-gray-300 ml-1"
                            : "text-gray-400 ml-2",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      <div className="ml-4 w-full">{item.name}</div>
                    </div>
                  ))}
                </nav>

                <div className="absolute inset-x-0 bottom-20 flex justify-center">
                  <a
                    href="/admin/notifications"
                    className="relative cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2.5}
                      stroke="currentColor"
                      width="24"
                      height="24"
                      className="text-gray-200 hover:text-blue-leve"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
                      />
                    </svg>
                    {unreadCount > 0 && (
                      <span className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full h-6 w-6 flex items-center justify-center text-xs">
                        {unreadCount}
                      </span>
                    )}
                  </a>
                </div>

                <div className="absolute inset-x-0 hover:bg-blue-cl hover:bg-opacity-40 hover:text-blue-leve bottom-4 flex justify-center w-full">
                  <LogoutModal isOpen={sidebarOpens} />{" "}
                  {/* Use sidebarOpens prop */}
                </div>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden fixed md:inset-y-0 md:flex md:flex-col">
          <div className="flex flex-shrink-0 items-center px-4">
            <div
              className={classNames(
                "flex-1 group fixed text-sm dark:border-r flex-row h-screen dark:bg-gradient-to-r dark:from-blue-green-dark dark:to-blue-green-dark fixed-100 bottom-0 left-0 text-gray-300 hover:bg-light-white z-10 top-0 transition-all duration-300",
                opens ? "w-64" : "w-20"
              )}
            >
              <div className="">
                <div
                  className={`bg-dark-purple pt-8 relative ${
                    opens ? "mb-4" : "mb-4"
                  }`}
                >
                  <div className="flex gap-x-4 items-center">
                    {opens ? (
                      <img
                        className="h-8 w-auto ml-4 mt-2"
                        src="https://www.eupago.pt/images/logo-light-normal.webp"
                        alt="Your Company"
                      />
                    ) : (
                      <img
                        className="h-8 w-auto ml-4 mt-2"
                        src={eupay}
                        alt="Your Company"
                      />
                    )}
                    <button
                      onClick={onToggles}
                      className="ml-auto focus:outline-none p-2 rounded-md text-gray-500 hover:text-gray-700"
                    >
                      {opens ? (
                        <XMarkIcon className="absolute cursor-pointer -right-5 top-9 w-9 h-9 bg-gradient-to-r from-blue-green-dark to-blue-green-dark text-white border-white border-2 p-2 rounded-full transform hover:rotate-45 transition-transform duration-300" />
                      ) : (
                        <Bars2Icon className="absolute cursor-pointer -right-5 top-9 w-9 h-9 bg-gradient-to-r from-blue-green-dark to-blue-green-dark text-white border-white border-2 p-2 rounded-full transform hover:rotate-45 transition-transform duration-300" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <nav className="py-4 ">
                  {navigation.map((item, index) => (
                    <div
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        "group flex p-2 text-sm items-center text-gray-300 hover:bg-light-white flex-grow h-full cursor-pointer",
                        current === index
                          ? "bg-green-cl text-white font-bold"
                          : "hover:bg-green-cl hover:bg-opacity-40 hover:text-blue-leve",
                        current === index ? "border-l-4 border-blue-leve" : ""
                      )}
                      onClick={() => {
                        if (item.component) {
                          // If the menu item has a component, render the component
                        } else {
                          // If the menu item does not have a component, navigate to the corresponding href
                          navigate(item.href);
                          setCurrent(index);
                          setSidebarOpens(false);
                        }
                      }}
                    >
                      <item.icon
                        className={classNames(
                          current === index
                            ? "text-gray-300 ml-1"
                            : "text-gray-400 ml-2",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {opens && (
                        <div className="ml-4 w-full ">
                          <span className="menu-item-text ">{item.name} </span>
                        </div>
                      )}
                    </div>
                  ))}
                </nav>
              </div>
              <div className="absolute inset-x-0 mr-6 bottom-20 flex justify-center">
                <a
                  href="/admin/notifications"
                  className="relative cursor-pointer group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2.5}
                    stroke="currentColor"
                    width="24"
                    height="24"
                    className="text-gray-200 group-hover:text-blue-leve"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
                    />
                  </svg>
                  {unreadCount > 0 && (
                    <span className="absolute -top-2 -right-1 bg-gradient-blue text-white rounded-full h-4 w-4 flex items-center justify-center text-xs">
                      {unreadCount}
                    </span>
                  )}
                  <div className="absolute bottom-full ml-7 mb-1 hidden group-hover:block bg-black text-white text-xs rounded-md py-1 px-2">
                    {unreadCount > 0
                      ? `Tem ${unreadCount} ${
                          unreadCount === 1 ? "notificação" : "notificações"
                        } por ler.`
                      : "Nenhuma notificação"}
                  </div>
                </a>
              </div>
              <div className="absolute inset-x-0 hover:bg-blue-cl hover:bg-opacity-40 hover:text-blue-leve bottom-4 flex justify-center w-full">
                <LogoutModal isOpen={opens} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            {!sidebarOpens && (
              <button
                type="button"
                className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setSidebarOpens(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars2Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
