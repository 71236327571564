import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorMessage from '../../components/ErrorMessage';
import SuccessMessage from '../../components/SuccessMessage';
import { URL } from '../../service/Service';

const CreateClient = ({ button1, button2 }) => {
  const [formData, setFormData] = useState({
    customerID: '',
    name: '',
    notes: '',
    url: '',
    dateCalculate: '',
  });

  const { id } = useParams();
  const [successMessage, setSuccessMessage] = useState('');
  const [messageErr, setMessageErr] = useState('');
  const [subtitleErr, setSubtitleErr] = useState('');
  const token = sessionStorage.getItem('jwt');

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  // Function to format the timestamp to "dd-mm-yyyy HH:mm:ss" format
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const clearErrorMessages = () => {
    setMessageErr('');
    setSubtitleErr('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSuccessMessage('');
    setMessageErr('');
    setSubtitleErr('');

    try {
      const formattedTimestamp = formatTimestamp(
        `${selectedDate} ${selectedTime}`
      );

      const response = await fetch(
        `${URL}/api/partners/${id}/costumer`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ...formData,
            dateCalculate: formattedTimestamp,
          }),
        }
      );

      const data = await response.json();
      if (data.success) {
        setSuccessMessage(data.message);
        setFormData({
          customerID: '',
          name: '',
          notes: '',
          url: '',
          dateCalculate: '',
        }); // Clear the form
        setMessageErr('');
      } else {
        setMessageErr(data.message);
        setSuccessMessage('Novo cliente criado com sucesso.');
        setTimeout(() => {
          window.location.reload(); // Reload the page after 3 seconds
        }, 2000);
      }
    } catch (error) {
      setMessageErr('Não foi possível criar o cliente.');
      setSubtitleErr(
        'Preencha todos os campos ou verifique se o ID de Cliente já existe e volte a tentar.'
      );
      setSuccessMessage('');
      setTimeout(() => {
        clearErrorMessages();
      }, 3000);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto p-4 space-y-6">
      {successMessage && <SuccessMessage message={successMessage} />}
      {messageErr && (
        <ErrorMessage
          messageErr={messageErr}
          subtitleErr={subtitleErr}
          duration={2000}
        />
      )}

      <div className="text-left mb-4">
        <h3 className="flex items-center text-2xl font-extrabold uppercase text-gray-900">
          Associar novo Cliente
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 ml-2 h-6"
          >
            <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01a.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
          </svg>
        </h3>
        <p className="text-sm text-gray-500">
          Crie um novo cliente para o seu parceiro.
        </p>
      </div>
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <label
              htmlFor="customerID"
              className="block text-sm font-medium text-gray-700"
            >
              ID de Cliente (CID)
            </label>
            <input
              type="text"
              name="customerID"
              id="customerID"
              autoComplete="customerID"
              value={formData.customerID}
              onChange={handleChange}
              className="block w-full rounded-md border-gray-300 focus:ring-green-500 focus:border-green-500 sm:text-sm"
              required
            />


          </div>

          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Nome
            </label>
            <input
              type="text"
              name="name"
              id="name"
              autoComplete="given-name"
              value={formData.name}
              onChange={handleChange}
              className="block w-full rounded-md border-gray-300 focus:ring-green-500 focus:border-green-500 sm:text-sm"
              required
            />
          </div>
        </div>
      </div>
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <label
              htmlFor="date"
              className="block text-sm font-medium text-gray-700"
            >
              Data do término dos cálculos
            </label>
            <input
              type="date"
              name="date"
              id="date"
              value={selectedDate}
              onChange={handleDateChange}
              className="block w-full rounded-md border-gray-300 focus:ring-green-500 focus:border-green-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label
              htmlFor="time"
              className="block text-sm font-medium text-gray-700"
            >
              Hora do término dos cálculos
            </label>
            <input
              type="time"
              name="time"
              id="time"
              value={selectedTime}
              onChange={handleTimeChange}
              className="block w-full rounded-md border-gray-300 focus:ring-green-500 focus:border-green-500 sm:text-sm"
              required
              step="1" // Add this step attribute for seconds
            />
          </div>
        </div>
      </div>
      <div>
        <label htmlFor="url" className="block text-sm font-medium text-gray-700">
          Website
        </label>
        <div className="flex rounded-md shadow-sm">
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
            https://
          </span>
          <input
            type="text"
            name="url"
            id="url"
            autoComplete="url"
            value={formData.url}
            onChange={handleChange}
            className="block w-full rounded-none rounded-r-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
          />
        </div>
      </div>
      <div className="space-y-4">
        <div>
          <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
            Notas
          </label>
          <textarea
            id="notes"
            name="notes"
            rows={3}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={formData.notes}
            onChange={handleChange}
          />
          <p className="mt-2 text-sm text-gray-500">
            Escreva algumas informações extras sobre o cliente.{' '}
            <span className="font-semibold text-gray-600">Não obrigatório.</span>
          </p>
        </div>
      </div>
      <div className="flex justify-end space-x-4">
        {button1}
        {button2}
      </div>
    </form>
  );
};

export default CreateClient;
